import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/ES/layout'

import pic11 from '../../assets/images/pic11.jpg'

const Address = (props) => (
    <Layout>
        <Helmet>
            <title>Unidans Project</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1> Who we address </h1>
                    </header>
                    <span className="image main"><img src={pic11} alt="" /></span>
                    
                    <h3> Beneficiarios directos </h3>
                        <ul>
                            <li> Entrenadores que
trabajan en el ámbito de deporte unificado/adaptado en general y en el de la danza
adaptada/unificada en particular. </li> 
                            <li>  Entidades no lucrativas/instituciones públicas/centros de formación
que trabajan en los ámbitos de la inclusión social y los deportes/danza y están interesados en
promover el desarrollo profesional de sus entrenadores en el campo de la danza
adaptada/unificada.</li>
                            <li> Personas con o sin discapacidad. </li>
                        
                        </ul>

                        <h3> Beneficiarios indirectos: </h3>
        <ul>
            <li>Familias enteras o miembros familiares al cuidado de
personas con discapacidad, representantes legales, especialistas y miembros de la comunidad.</li>
            <li> Representantes de
instituciones de política pública en los países participantes.</li>
            <li> Representantes de instituciones
públicas y privadas. </li>
        </ul>

                </div>
            </section>
        </div>

    </Layout>
)

export default Address